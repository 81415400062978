import { lazy } from "react";
import {
  Layout as LayoutIcon,
  Sliders as SlidersIcon,
  Edit as EditIcon,
  Users as UsersIcon,
  Clipboard as ClipboardIcon,
  FileText as FileTextIcon,
  UserCheck as UserCheckIcon,
  FileMinus,
  MessageCircle,
  CreditCard as CreditCardIcon,
} from "react-feather";
import LandingPage from "../pages/auth/LandingPage";
const CourseEditor = lazy(() => import("../pages/adminEditor/courseEditor"));
const ModuleEditor = lazy(() => import("../pages/adminEditor/moduleEditor"));
const ItemEditor = lazy(() => import("../pages/adminEditor/itemEditor"));


// Auth
const Login = lazy(() => import("../pages/auth/Login"));
const AdminLogin = lazy(() => import("../pages/auth/AdminLogin"));
const SignUp = lazy(() => import("../pages/auth/SignUp"));
const ForgetPassword = lazy(() => import("../pages/auth/ForgetPassword"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));
const VerifyEmail = lazy(() => import("../pages/auth/VerifyEmail"));
const Page404 = lazy(() => import("../pages/auth/Page404"));
const Page500 = lazy(() => import("../pages/auth/Page500"));
// Course
const Modules = lazy(() => import("../pages/courses/Modules"));
const Page = lazy(() => import("../pages/courses/Page"));
const Discussion = lazy(() => import("../pages/courses/Discussion"));
const Syllabus = lazy(() => import("../pages/courses/Syllabus"));

// Pages
const Profile = lazy(() => import("../pages/pages/Profile"));
const ChatBot = lazy(() => import("../pages/pages/ChatBot"));
const Courses = lazy(() => import("../pages/courses/Courses"));
const GradeBook = lazy(() => import("../pages/pages/GradeBook"));
const Privacy = lazy(() => import("../pages/pages/Privacy"));
const Help = lazy(() => import("../pages/pages/Help"));
const Enrollment = lazy(() => import("../pages/enrollment/Enrollment"));
const Application = lazy(() => import("../pages/enrollment/Application"));
const CourseDetails = lazy(() =>
  import("../pages/enrollment/student/courseDetails/CourseDetails")
);
const Classes = lazy(() => import("../pages/classes/Classes"));
const Grades = lazy(() => import("../pages/classes/grades/Grades"));
const ViewExercise = lazy(() =>
  import("../pages/classes/viewExercise/viewExercise")
);




const StudyProgress = lazy(() =>
  import("../pages/classes/studyProgress/StudyProgress")
);
const Apply = lazy(() => import("../pages/apply/Apply"));
const StatusCheck = lazy(() => import("../pages/statusCheck/StatusCheck"));
const AdminCourse = lazy(() => import("../pages/adminCourse/AdminCourse"));
const Payment = lazy(() => import("../pages/payment/Payment.js"));
const PaymentMethod = lazy(() =>
  import("../pages/payment/PaymentMethod/PaymentMethod")
);
const Wallet = lazy(() => import("../pages/wallet/Wallet"));
const AdminWallet = lazy(() => import("../pages/adminWallet/AdminWallet"));

// Routes
const landingRoutes = {
  path: "/",
  name: "Landing Page",
  component: LandingPage,
  children: null,
};

const loginRoutes = {
  path: "/login",
  name: "Sign In",
  component: Login,
  children: null,
};

const AdminLoginRoutes = {
  path: "/admin-login",
  name: "Admin Sign In",
  component: AdminLogin,
  children: null,
};

const applyRoutes = {
  path: "/apply",
  name: "Apply Now",
  component: Apply,
  children: null,
};

const statusCheckRoutes = {
  path: "/status",
  name: "Status",
  icon: UserCheckIcon,
  component: StatusCheck,
  children: null,
};

const chatBotRoutes = {
  path: "/ai-assistant",
  name: "AI Course Assistant",
  icon: MessageCircle,
  component: ChatBot,
  children: null,
};

const dashboardRoutes = {
  path: "/dashboard",
  name: "Student Center",
  header: "Pages",
  icon: SlidersIcon,
  containsHome: true,
  children: [
    {
      path: "/dashboard/profile",
      name: "Profile",
      component: Profile,
    },
    // {
    //   path: "/dashboard/settings",
    //   name: "Settings",
    //   component: Settings
    // },
  ],
};

const privacyRoutes = {
  path: "/pages",
  name: "Privacy & Policy",
  icon: LayoutIcon,
  children: [
    {
      path: "/pages/privacy",
      name: "Privacy",
      component: Privacy,
    },
    {
      path: "/pages/help",
      name: "Help",
      component: Help,
    },
  ],
};

const pageRoutes = {
  path: "/pages",
  name: "Online Study",
  icon: LayoutIcon,
  children: [
    // {
    //   path: "/pages/clients",
    //   name: "Clients",
    //   component: Clients
    // },
    {
      path: "/pages/courses",
      name: "Courses",
      component: Courses,
    },
    // {
    //   path: "/pages/invoice",
    //   name: "Invoice",
    //   component: Invoice
    // },
    // {
    //   path: "/pages/tasks",
    //   name: "Tasks",
    //   component: Tasks
    // },
    // {
    //   path: "/pages/liveroom",
    //   name: "Live Study",
    //   component: LiveRooms
    // },
    {
      path: "/pages/gradebook",
      name: "Grade Book",
      component: GradeBook,
    },
    // {
    //   path: "/pages/chat",
    //   name: "Chat",
    //   component: Chat,
    //   badgeColor: "primary",
    //   badgeText: "New"
    // },
  ],
};

const enrollmentRoutes = {
  path: "/enrollment",
  name: "Enrollment",
  icon: ClipboardIcon,
  component: Enrollment,
  children: null,
};

const applicationRoutes = {
  path: "/application",
  name: "Application",
  icon: ClipboardIcon,
  component: Application,
  children: null,
};

const enrollmentSubRoutes = {
  path: "/enrollment",
  name: "Enrollment",
  icon: ClipboardIcon,
  component: Enrollment,
  children: [
    {
      path: "/enrollment/courseDetails",
      name: "Course Details",
      component: CourseDetails,
    },
  ],
};

const classesRoutes = {
  path: "/classes",
  name: "Classes",
  icon: FileTextIcon,
  component: Classes,
  children: null,
};

const classesSubRoutes = {
  path: "/classes",
  name: "Classes",
  icon: FileTextIcon,
  children: [
    {
      path: "/classes/studyProgress/:id/:programId",
      name: "StudyProgress",
      component: StudyProgress,
    },
    {
      path: "/classes/grades/:sid",
      name: "Grades",
      component: Grades,
    },
    {
      path: "/classes/:courseId/viewExercise/:itemId/:email",
      name: "ViewExercise",
      component: ViewExercise,
    },
  ],
};

const courseEditorRoutes = {
  path: "/courseContentEditor",
  name: "Course Content Editor",
  icon: EditIcon,
  component: CourseEditor,
  children: null,
}



const courseEditorSubRoutes = {
  path: "/courseContentEditor",
  name: "Course Content Editor",
  icon: EditIcon,
  children: [
    {
      path: "/courseContentEditor/:id/syllabus",
      exact: true,
      name: "_Syllabus",
      component: Syllabus,
    },
    {
      path: "/courseContentEditor/:courseId/modules",
      exact: true,
      name: "Editor Course Modules",
      component: ModuleEditor,
    },
    {
      path: "/courseContentEditor/:courseId/item/:itemId",
      exact: true,
      name: "Editor Course Items",
      component: ItemEditor,
    },
  ]
}

const adminStudyRoutes = {
  path: "/adminCourse",
  name: "Online Study",
  icon: FileMinus,
  children: [
    {
      path: "/adminCourse/courses",
      name: "Registration",
      component: AdminCourse,
    },
  ],
};

const authRoutes = {
  path: "/auth",
  name: "Settings Center",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: Login,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/forget-password",
      name: "Forget Password",
      component: ForgetPassword,
    },
    {
      path: "/auth/verify-email",
      name: "Verify Email",
      component: VerifyEmail,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
};

const courseRoutes = {
  path: "/courses",
  name: "Courses",
  icon: LayoutIcon,
  children: [
    {
      path: "/courses/:id/modules",
      name: "Modules",
      component: Modules,
    },
    {
      path: "/courses/:id/pages/:title",
      name: "_Pages",
      component: Page,
    },
    {
      path: "/courses/:id/discussion/:sub_id",
      name: "_Discussion",
      component: Discussion,
    },
    {
      path: "/courses/:id/syllabus",
      name: "_Syllabus",
      component: Syllabus,
    },
  ],
};

const paymentRoutes = {
  path: "/payment",
  name: "Payment",
  icon: FileTextIcon,
  children: [
    {
      path: "/payment",
      name: "Payment",
      component: Payment,
    },
    {
      path: "/payment/method",
      name: "Payment Method",
      component: PaymentMethod,
    },
  ],
};

const walletRoutes = {
  path: "/wallet",
  name: "Wallet",
  icon: CreditCardIcon,
  component: Wallet,
  children: null,
};

const adminWalletRoutes = {
  path: "/adminWallet",
  name: "Wallet",
  icon: CreditCardIcon,
  component: AdminWallet,
  children: null,
};

// Dashboard specific routes
export const dashboard = [
  pageRoutes,
  // layoutRoutes,
  courseRoutes,
  // privacyRoutes,
  enrollmentRoutes,
  statusCheckRoutes,
  // chatBotRoutes,
  walletRoutes,
  // adminStudyRoutes,
  // applyRoutes,
  // iconRoutes,
  // formRoutes,
  // tableRoutes,
  // formPluginsRoutes,
  // advancedTablesRoutes,
  // chartRoutes,
  // notificationsRoutes,
  // mapRoutes,
  // calendarRoutes,
  // privateRoutes
];

// Landing specific routes
export const landing = [landingRoutes];
export const login = [loginRoutes];
export const adminLogin = [AdminLoginRoutes];
export const apply = [applyRoutes];
// Auth specific routes
export const page = [authRoutes];

// Enrollment routes
export const enrollmentSub = [enrollmentSubRoutes];

export const classesSub = [classesSubRoutes];
// export const online = [adminStudyRoutes];


export const courseEditorSub = [courseEditorSubRoutes];
export const online = [adminStudyRoutes];
export const paymentSub = [paymentRoutes];

// All routes
export const routes = [
  // dashboardRoutes,
  enrollmentRoutes,
  pageRoutes,
  statusCheckRoutes,
  // chatBotRoutes,
  walletRoutes,
  // applyRoutes,
  // layoutRoutes,
  // iconRoutes,
  // formRoutes,
  // tableRoutes,
  // formPluginsRoutes,
  // advancedTablesRoutes,
  // chartRoutes,
  // notificationsRoutes,
  // mapRoutes,
  // calendarRoutes
];

export const noRole = [privacyRoutes, dashboardRoutes, paymentRoutes];
export const adminRoutes = [
  classesRoutes,
  applicationRoutes,
  adminStudyRoutes,
  adminWalletRoutes,
  courseEditorRoutes
];


export const taRoutes = [enrollmentRoutes];
