import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  landing as landingRoutes,
  login as loginRoutes,
  adminLogin as AdminLoginRoutes,
  apply as apllyRoutes,
  dashboard as dashboardRoutes,
  noRole as noRoleRoutes,
  page as pageRoutes,
  enrollmentSub as enrollmentSubRoutes,
  adminRoutes as adminDashboardRoutes,
  classesSub as classesSubRoutes,
  courseEditorSub as courseEditorSubRoutes,
  online as onlineStudyRoutes,
  paymentSub as paymentSubRoute,
} from "./index";
import DashboardLayout from "../layouts/Dashboard";
import AdminDashboardLayout from "../layouts/AdminDashboard";
import AuthLayout from "../layouts/Auth";
import NewWindowLayout from "../layouts/NewWindow";
import ApplyLayout from "../layouts/Apply";
import RoleLayout from "../layouts/RoleBackground";
import Admin from "../layouts/Admin";
import Page404 from "../pages/auth/Page404";
import ScrollToTop from "../components/ScrollToTop";
import { Spin } from "antd";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        {childRoutes(AuthLayout, landingRoutes)}
        <Suspense fallback={
            <div className="w-100 vertically-view-center">
              <Spin tip="Loading" size="large"/>
            </div>
        }>
          {childRoutes(RoleLayout, loginRoutes)}
          {childRoutes(Admin, AdminLoginRoutes)}
          {childRoutes(ApplyLayout, apllyRoutes)}
          {childRoutes(DashboardLayout, dashboardRoutes)}
          {childRoutes(AdminDashboardLayout, adminDashboardRoutes)}
          {childRoutes(RoleLayout, pageRoutes)}
          {childRoutes(NewWindowLayout, noRoleRoutes)}
          {childRoutes(AdminDashboardLayout, enrollmentSubRoutes)}
          {childRoutes(AdminDashboardLayout, classesSubRoutes)}
          {childRoutes(AdminDashboardLayout, courseEditorSubRoutes)}
          {/* {childRoutes(AuthLayout, onlineStudyRoutes)} */}
          {/* {childRoutes(AdminDashboardLayout, paymentSubRoute)} */}
        </Suspense>
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default Routes;
