import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { signOut } from "../redux/actions/layoutActions";

import {
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { AlertCircle, Bell, Home, User, UserPlus, Menu } from "react-feather";

import OxygenAvatar from "../components/OxygenAvatar";
import { getRoleFromLocalStorage } from "../constants";
import { checkReadViewMode } from "../pages/courses/utils";
import { message } from "antd";
import { useHistory } from "react-router";

const notifications = [];

const NavbarDropdown = ({
  children,
  count,
  showBadge,
  header,
  footer,
  icon: Icon,
}) => (
  <UncontrolledDropdown nav inNavbar className="mr-2">
    <DropdownToggle nav className="nav-icon">
      <div className="position-relative">
        <Icon className="align-middle" size={18} />
        {showBadge ? <span className="indicator">{count}</span> : null}
      </div>
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-lg py-0">
      <div className="dropdown-menu-header position-relative">
        {count} {header}
      </div>
      <ListGroup>{children}</ListGroup>
      <DropdownItem header className="dropdown-menu-footer">
        <span className="text-muted">{footer}</span>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const NavbarDropdownItem = ({ icon, title, description, time, spacing }) => (
  <ListGroupItem>
    <Row noGutters className="align-items-center">
      <Col xs={2}>{icon}</Col>
      <Col xs={10} className={spacing ? "pl-2" : null}>
        <div className="text-dark">{title}</div>
        <div className="text-muted small mt-1">{description}</div>
        <div className="text-muted small mt-1">{time}</div>
      </Col>
    </Row>
  </ListGroupItem>
);

const NavbarComponent = ({ layout, dispatch }) => {
  const [email, setEmail] = useState("");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const history = useHistory();
  const readView = checkReadViewMode(document.location.search);
  const role = getRoleFromLocalStorage();
  const mobileMenuRef = useRef(null);

  useEffect(() => {
    if (layout.user && layout.user.email) {
      setEmail(layout.user.email);
    }
  }, [layout.user]);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobileMenuOpen && mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
        event.preventDefault();
        event.stopPropagation();
      }
    };

    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling when menu is open
    } else {
      document.body.style.overflow = 'auto';
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  const signOutBack = () => {
    const successMessage = "Logout success";
    const logoutPath = role === "student" ? "/login" : "/admin-login";
    message.success(successMessage);
    localStorage.setItem("manualLogout", "true");
    // dispatch(signOut());
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroidOrIOS = /android|iphone|ipad|ipod/.test(userAgent);
    
    if (!isAndroidOrIOS) {
      dispatch(signOut());
      history.push(logoutPath);
    } else {
      localStorage.removeItem('role');
      localStorage.removeItem('JWT');
      localStorage.removeItem('email');
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'redirect_to_login'
      }));
    }
    localStorage.setItem("role", "");
  };

  return (
    <Navbar
      color="white"
      light
      expand
      style={readView ? { display: "none" } : undefined}
    >
      <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => {
          dispatch(toggleSidebar());
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          <NavbarDropdown
            header="New Notifications"
            footer="Show all notifications"
            icon={Bell}
            count={notifications.length}
            showBadge
          >
            {notifications?.map((item, key) => {
              let icon = <Bell size={18} className="text-warning" />;

              if (item.type === "important") {
                icon = <AlertCircle size={18} className="text-danger" />;
              }

              if (item.type === "login") {
                icon = <Home size={18} className="text-primary" />;
              }

              if (item.type === "request") {
                icon = <UserPlus size={18} className="text-success" />;
              }

              return (
                <NavbarDropdownItem
                  key={key}
                  icon={icon}
                  title={item.title}
                  description={item.description}
                  time={item.time}
                />
              );
            })}
          </NavbarDropdown>
          <div className="user-menu-container">
            <div className="desktop-menu">
              <UncontrolledDropdown nav inNavbar>
                <span className="d-sm-inline-block">
                  <DropdownToggle nav caret>
                    <OxygenAvatar
                      marginLeft={"auto"}
                      width={25}
                      height={25}
                      username={email}
                    />
                  </DropdownToggle>
                </span>
                <DropdownMenu right>
                  <DropdownItem>
                    <User size={18} className="align-middle mr-2" />
                    <a href="/dashboard/profile">Profile</a>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    <a href="/pages/privacy">Privacy & Policy</a>
                  </DropdownItem>
                  <DropdownItem>
                    <a href="/pages/help">Help</a>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={signOutBack}>Sign Out</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            <div className="mobile-menu">
              <button 
                className="mobile-menu-button"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              >
                <OxygenAvatar
                  width={25}
                  height={25}
                  username={email}
                />
              </button>
              
              <div className={`mobile-menu-overlay ${isMobileMenuOpen ? 'open' : ''}`} />
              <div ref={mobileMenuRef} className={`mobile-menu-items ${isMobileMenuOpen ? 'open' : ''}`}>
                <div className="mobile-menu-header">
                  <OxygenAvatar
                    width={40}
                    height={40}
                    username={email}
                  />
                  <span className="mobile-menu-email">{email}</span>
                </div>
                <div className="mobile-menu-item" onClick={() => history.push('/dashboard/profile')}>
                  <User size={18} className="align-middle mr-2" />
                  Profile
                </div>
                <div className="mobile-menu-divider" />
                <div className="mobile-menu-item" onClick={() => history.push('/pages/privacy')}>
                  Privacy & Policy
                </div>
                <div className="mobile-menu-item" onClick={() => history.push('/pages/help')}>
                  Help
                </div>
                <div className="mobile-menu-divider" />
                <div className="mobile-menu-item" onClick={signOutBack}>
                  Sign Out
                </div>
              </div>
            </div>
          </div>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default connect((store) => ({
  layout: store.layout,
}))(NavbarComponent);
